// general
.element-invisible {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
}
.hide {
  display: none;
}
.invisible {
  visibility: hidden;
}
.clear {
  display: block;
  clear: both;
}
.clear-after:after {
  display: table;
  clear: both;
  content: '';
}

// floats
.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}

// alignment
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}

// visiblity classes
  // uncomment if not using neat
    // .show-for-medium {
    //   @include respond-to('small-only') {
    //     display: none !important;
    //   }
    // }
    // .show-for-large {
    //   @include respond-to('small-only') {
    //     display: none !important;
    //   }
    //   @include respond-to('medium-only') {
    //     display: none !important;
    //   }
    // }
    // .show-for-small-only {
    //   @include respond-to('medium') {
    //     display: none !important;
    //   }
    // }
    // .show-for-medium-only {
    //   @include respond-to('small-only') {
    //     display: none !important;
    //   }
    //   @include respond-to('large') {
    //     display: none !important;
    //   }
    // }
    // .show-for-large-only {
    //   @include respond-to('small-only') {
    //     display: none !important;
    //   }
    //   @include respond-to('medium-only') {
    //     display: none !important;
    //   }
    // }
    // .hide-for-medium {
    //   @include respond-to('medium') {
    //     display: none !important;
    //   }
    // }
    // .hide-for-large {
    //   @include respond-to('large') {
    //     display: none !important;
    //   }
    // }
    // .hide-for-medium-only {
    //   @include respond-to('medium-only') {
    //     display: none !important;
    //   }
    // }
    // .hide-for-large-only {
    //   @include respond-to('large-only') {
    //     display: none !important;
    //   }
    // }

  // uncomment if using neat
    // .show-for-medium {
    //   @include media('small-only') {
    //     display: none !important;
    //   }
    // }
    // .show-for-large {
    //   @include media('small-only') {
    //     display: none !important;
    //   }
    //   @include media('medium-only') {
    //     display: none !important;
    //   }
    // }
    // .show-for-small-only {
    //   @include media('medium') {
    //     display: none !important;
    //   }
    // }
    // .show-for-medium-only {
    //   @include media('small-only') {
    //     display: none !important;
    //   }
    //   @include media('large') {
    //     display: none !important;
    //   }
    // }
    // .show-for-large-only {
    //   @include media('small-only') {
    //     display: none !important;
    //   }
    //   @include media('medium-only') {
    //     display: none !important;
    //   }
    // }
    // .hide-for-medium {
    //   @include media('medium') {
    //     display: none !important;
    //   }
    // }
    // .hide-for-large {
    //   @include media('large') {
    //     display: none !important;
    //   }
    // }
    // .hide-for-medium-only {
    //   @include media('medium-only') {
    //     display: none !important;
    //   }
    // }
    // .hide-for-large-only {
    //   @include media('large-only') {
    //     display: none !important;
    //   }
    // }